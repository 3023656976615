<template>
  <div class="page-wrapper container">
    <h1 class="page-title" data-aos="fade-up">Museums & Galleries</h1>
    <div class="page-desc" data-aos="fade-up">
      <span>{{ description }}</span>
    </div>

    <div class="app-data-filter-wrapper">
      <AppDataFilterOpenButton @click="handleOpenFilter" />
    </div>

    <MsgEntityCollectionEmpty v-if="!loadingContentData && emptyContentData" name="Art Spaces" />

    <PageItemsGroup
      v-if="museumArtSpaces.length"
      :base-type="'art-spaces'"
      :has-more="getMore.museumArtSpaces"
      :items="museumArtSpaces"
      :title="'museums'"
      :type="'museumArtSpaces'"
      @load-more="loadMore"
    />
    <PageItemsGroup
      v-if="galleryArtSpaces.length"
      :base-type="'art-spaces'"
      :has-more="getMore.galleryArtSpaces"
      :items="galleryArtSpaces"
      :title="'galleries'"
      :type="'galleryArtSpaces'"
      @load-more="loadMore"
    />
    <PageItemsGroup
      v-if="historicalSites.length"
      :base-type="'historical-sites'"
      :has-more="getMore.historicalSites"
      :items="historicalSites"
      :title="'historical sites'"
      :type="'historicalSites'"
      @load-more="loadMore"
    />

    <AppDataFilterMain
      v-model="filterValues"
      :filter-groups="filterGroups"
      :init-open="isAppDataFilterOpened"
      :is-mobile="isMobileScreen"
      @input="handleChangeFilterValues"
      @open="handleOpenFilter"
      @close="handleCloseFilter"
    />
  </div>
</template>

<script>
import artSpaceBaseType from '@/enums/artSpaceBaseType';
import { areRouteQueriesDifferent } from '@/helpers/routerHelper';
import { EntityTypes } from '@/helpers/entityType';
import { navigatorCurrentPosition } from '@/helpers/GeolocationHelper';
import ArtSpaceDataFilterHelper from '@/helpers/data-filter/ArtSpaceDataFilterHelper';

import pageMetadataQuery from '@/graphql/PageMetadata.query.gql';
import artSpacesQuery from '@/graphql/artSpace/ArtSpaces.query.gql';
import historicalSitesQuery from '@/graphql/historicalSite/HistoricalSites.query.gql';
import artSpacesPageFilterQuery from '@/graphql/artSpace/ArtSpacesPageFilter.query.gql';

import AppDataFilterMain from '@/components/data-filter/AppDataFilterMain';
import PageItemsGroup from '@/components/partials/PageItemsGroup';
import AppDataFilterOpenButton from '@/components/data-filter/AppDataFilterOpenButton';
import MsgEntityCollectionEmpty from '@/components/MsgEntityCollectionEmpty.vue';

const DATA_PER_PAGE = 6;

const AS_MUSEUM_DATA_TYPE = `${artSpaceBaseType.museum}ArtSpaces`;
const AS_GALLERY_DATA_TYPE = `${artSpaceBaseType.gallery}ArtSpaces`;
const HISTORICAL_SITES_DATA_TYPE = 'historicalSites';

export default {
  name: 'ArtSpacesPage',
  components: {
    MsgEntityCollectionEmpty,
    AppDataFilterOpenButton,
    AppDataFilterMain,
    PageItemsGroup,
  },
  metaInfo: {
    title: 'Museums & Galleries',
  },
  data() {
    return {
      description: '',
      museumArtSpaces: [],
      galleryArtSpaces: [],
      historicalSites: [],
      loading: {
        museumArtSpaces: true,
        galleryArtSpaces: true,
        historicalSites: true,
      },
      getMore: {
        museumArtSpaces: false,
        galleryArtSpaces: false,
        historicalSites: false,
      },
      page: {
        museumArtSpaces: 1,
        galleryArtSpaces: 1,
        historicalSites: 1,
      },
      filterGroups: [],
      filterValues: {},
      changingRouteAfterFilterUpdated: false,
    };
  },
  computed: {
    emptyContentData() {
      return !this.museumArtSpaces.length && !this.galleryArtSpaces.length && !this.historicalSites.length;
    },
    filterQueryVariables() {
      return ArtSpaceDataFilterHelper.prepareFilterValuesToQueryVariables(this.filterValues);
    },
    isAppDataFilterOpened() {
      return !!this.$store.state.isAppDataFilterOpened;
    },
    isMobileScreen() {
      return !!this.$store.state.isMobileScreen;
    },
    loadingContentData() {
      return this.loading.museumArtSpaces || this.loading.galleryArtSpaces || this.loading.historicalSites;
    },
  },
  apollo: {
    pageMetadata: {
      query: pageMetadataQuery,
      variables() {
        return {
          entityType: EntityTypes.art_space,
        };
      },
      result(result) {
        this.description = result.data.pageMetadata?.description;
      },
    },
  },
  created() {
    navigatorCurrentPosition(
      () => {
        this.$store.dispatch('showOrHidePermissionsModal', false);
      },
      () => {
        this.$store.dispatch('showOrHidePermissionsModal', true);
      }
    );

    this.filterValues = ArtSpaceDataFilterHelper.getFilterValuesFromRouteQuery(this.$route.query);
    this.fetchPageContentData();
    this.getFilterData();
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.changingRouteAfterFilterUpdated) {
      this.filterValues = ArtSpaceDataFilterHelper.getFilterValuesFromRouteQuery(to.query);
    }
    this.resetFetchDataOptions();
    this.fetchPageContentData();
    this.changingRouteAfterFilterUpdated = false;
    next();
  },
  methods: {
    fetchPageContentData() {
      this.fetchArtSpaces(AS_MUSEUM_DATA_TYPE);
      this.fetchArtSpaces(AS_GALLERY_DATA_TYPE);
      this.fetchHistoricalSites();
    },
    fetchArtSpaces(type) {
      const baseType = type === AS_MUSEUM_DATA_TYPE ? artSpaceBaseType.museum : artSpaceBaseType.gallery;

      this.loading[type] = true;
      this.$apollo
        .query({
          query: artSpacesQuery,
          fetchPolicy: 'no-cache',
          variables: {
            baseType,
            limit: DATA_PER_PAGE,
            page: this.page[type],
            ...this.filterQueryVariables,
          },
        })
        .then(({ data }) => {
          this[type].push(...data.artSpaces.data);
          this.getMore[type] = data.artSpaces.hasMorePages;
          this.loading[type] = false;
        })
        .catch(() => {
          this.loading[type] = false;
        });
    },
    fetchHistoricalSites() {
      const { hasAdmissionByDigitalCard, partnerMuseum } = this.filterQueryVariables.filters;
      if (hasAdmissionByDigitalCard || partnerMuseum) {
        this.historicalSites = [];
        this.getMore.historicalSites = false;
        this.loading.historicalSites = false;
        return;
      }

      this.loading.historicalSites = true;
      this.$apollo
        .query({
          query: historicalSitesQuery,
          fetchPolicy: 'no-cache',
          variables: {
            limit: DATA_PER_PAGE,
            page: this.page.historicalSites,
            ...this.filterQueryVariables,
          },
        })
        .then(({ data }) => {
          this.historicalSites.push(...data.historicalSites.data);
          this.getMore.historicalSites = data.historicalSites.hasMorePages;
          this.loading.historicalSites = false;
        })
        .catch(() => {
          this.loading.historicalSites = false;
        });
    },
    getFilterData() {
      return this.$apollo
        .query({
          query: artSpacesPageFilterQuery,
          fetchPolicy: 'no-cache',
        })
        .then(({ data = {} }) => {
          this.filterGroups = ArtSpaceDataFilterHelper.prepareFilterGroups(data);
        });
    },
    handleOpenFilter() {
      this.$store.dispatch('toggleAppDataFilter', true);
    },
    handleCloseFilter() {
      this.$store.dispatch('toggleAppDataFilter', false);
    },
    handleChangeFilterValues() {
      const query = ArtSpaceDataFilterHelper.setFilterValuesToRouteQuery(this.filterValues);

      if (areRouteQueriesDifferent(this.$route.query, query)) {
        this.changingRouteAfterFilterUpdated = true;
        this.$router.push({ name: this.$route.name, params: this.$route.params, query });
      }
    },
    loadMore(type) {
      if (this.getMore[type]) {
        this.page[type]++;

        if (type === HISTORICAL_SITES_DATA_TYPE) {
          this.fetchHistoricalSites();
        } else {
          this.fetchArtSpaces(type);
        }
      }
    },
    resetFetchDataOptions() {
      this.loading.museumArtSpaces = true;
      this.loading.galleryArtSpaces = true;
      this.loading.historicalSites = true;
      this.page.museumArtSpaces = 1;
      this.page.galleryArtSpaces = 1;
      this.page.historicalSites = 1;
      this.museumArtSpaces = [];
      this.galleryArtSpaces = [];
      this.historicalSites = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  margin-top: 100px;
  padding-bottom: 60px;

  @media (max-width: 767px) {
    .open {
      position: fixed;
      top: 0;
      padding-top: 20px;
      left: 0;
    }
  }
}
.page-desc {
  max-width: 550px;
}
</style>
